/*
 * Localized default methods for the jQuery validation plugin.
 * Locale: FI
 */
$.extend($.validator.methods, {
  date(value, element) {
    return this.optional(element) || /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value);
  },
  number(value, element) {
    return this.optional(element) || /^-?(?:\d+)(?:,\d+)?$/.test(value);
  },
});
